body {
    background-color: #1f1f1f;
    color: #c8cfca;
    font-family: 'Helvetica', Arial, sans-serif;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    margin: 60 auto;
    margin-top: 100px;
}

.dashboard-card {
    background-color: #2c2c2c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.config-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.input-field, .select-field {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #3a3a3a;
    color: #ffffff;
    margin-top: 10px;
    width: 100%;
    max-width: 300px;
}

.button {
    background-color: #1e5a8b;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
}

.button:hover {
    background-color: #4594a0;
}

.alpha-control {
    margin-top: 20px;
    text-align: center;
}

.chart-container {
    position: relative;
    margin: auto;
    height: 300px;
    width: 100%;
    max-width: 600px;
}

h1, h3, p {
    text-align: center;
}

.table-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    height: 400px;
    max-height: 500px;
    margin: 0 auto;
    overflow: hidden;
}

.table-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.responsive-table {
    width: 100%;
    border-collapse: collapse;
}

.center-button {
    display: block;
    margin: 20px auto;
}

input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

table {
    margin: 20px auto;
    width: 100%;
    max-width: 600px;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border: 1px solid #c8cfca;
    text-align: center;
}

th {
    background-color: #3a3a3a;
}

td {
    background-color: #2c2c2c;
}


.logout-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .logout-button:hover {
    background-color: #ff1a1a;
  }
  