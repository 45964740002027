/* Contenedor principal */
.login-container {
  text-align: center;
  background-color: #2a2a2a; /* Fondo oscuro */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  margin: 20px 20px;
}

/* Título principal */
.login-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #ffffff;
}

/* Mensaje de redirección */
.login-message {
  font-size: 16px;
  color: #cccccc;
  margin-top: 20px;
}

/* Botón */
.login-button {
  padding: 12px 24px;
  background-color: #1e5a8b; 
  border: none;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff; /* Texto oscuro */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-button:hover {
  background-color: #5b90d9; /* Color más oscuro al pasar el mouse */
  transform: scale(1.05); /* Animación de hover */
}

.login-button:active {
  transform: scale(0.98); /* Animación al hacer clic */
}


.login-image {
  max-width: 100%; /* Ajusta la imagen para que no se pase del ancho del contenedor */
  height: auto; /* Mantiene las proporciones de la imagen */
  margin-bottom: 10px;
  margin-top: 20px; /* Espacio entre la imagen y el siguiente contenido */
  border-radius: 10px; /* Bordes redondeados opcionales */
}