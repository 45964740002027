.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 98%;
  background-color: rgba(42, 42, 42, 0.95); /* Fondo semi-transparente */
  color: #ffffff;
  padding: 15px 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  font-family: "Poppins", Helvetica;
}

.navbar:hover {
  background-color: rgba(42, 42, 42, 1); /* Fondo sólido al pasar el mouse */
}

.navLeft {
  font-size: 14px; 
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.navTitle {
  margin: 0;
  font-size: 16px;
  font-family: 'Helvetica', Arial, sans-serif;
}

.navRight {
  position: relative;
}

.logoutButton {
  padding: 12px 24px;
  background-color: #1e5a8b; 
  border: none;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff; /* Texto oscuro */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logoutButton:hover {
  background-color: #5b90d9; /* Color más oscuro al pasar el mouse */
  transform: scale(1.05); /* Animación de hover */
}

/* Responsive design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    display: flex;
    justify-content: center; 
    align-items: center;
  }

  .navLeft {
    margin-bottom: 10px;
  }

  .logoutButton {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center;
  }
}
